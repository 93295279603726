import { Elements } from '@stripe/react-stripe-js';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { useCreatePaymentIntent } from '../AwaitingPayment';

import { PaymentForm } from './components';
import { useConfirmPaymentIntent } from './hooks/useCOnfirmPaymentIntent';
import useGetStripePromise from './hooks/useGetStripePromise';
import InvoiceSummary from './InvoiceSummary';

const InvoicePaymentForm = ({
  invoice,
  resetPaymentFormErrors,
  additionalErrors,
  quote,
  isLoading,
  children,
  isDisabled,
  onSubmit,
  claimGroupId,
  fileId,
}) => {
  const intl = useIntl();
  const stripePromise = useGetStripePromise();
  const { pushError } = useToasts();

  const invoiceId = invoice?.id;

  const { handleConfirmPaymentIntent } = useConfirmPaymentIntent({
    invoiceId,
    onSuccess: onSubmit,
    claimGroupId,
    fileId,
  });
  const paymentIntentClientSecret = useCreatePaymentIntent({
    invoiceId,
  });

  const handleSubmit = useCallback(async () => {
    try {
      await handleConfirmPaymentIntent();
    } catch (error) {
      pushError(error);
    }
  }, [handleConfirmPaymentIntent, pushError]);

  const handleResetPaymentFormErrors = useCallback(
    () => resetPaymentFormErrors(invoiceId),
    [invoiceId, resetPaymentFormErrors],
  );

  if (!invoice) {
    return <></>;
  }

  return (
    <>
      <InvoiceSummary {...invoice} quote={quote} />

      <Elements options={{ locale: intl.locale }} stripe={stripePromise()}>
        <PaymentForm
          amount={invoice.totalPriceWithTax}
          additionalErrors={additionalErrors || invoice.errors}
          resetAdditionalErrors={handleResetPaymentFormErrors}
          isLoading={invoice.isPending || isLoading}
          onSubmit={handleSubmit}
          isDisabledFromParent={isDisabled}
          paymentIntentClientSecret={paymentIntentClientSecret}
        >
          {children}
        </PaymentForm>
      </Elements>
    </>
  );
};

InvoicePaymentForm.displayName = 'InvoicePaymentForm';

export default InvoicePaymentForm;
