import { createSelector } from 'reselect';

import { SellerConfiguration } from '@savgroup-front-common/types';

import { CommonAppState } from '../CommonAppState';
import { Selectors as requestSelectors } from '../sagaRequestMetadata';

import * as actionTypes from './actionTypes';
import { ErrorFromBack } from '../../helpers';

interface SellerConfigArgs {
  timestamp: string;
}

const sellerState = (state: CommonAppState) => state.sellerConfiguration;

export const sellerConfig = createSelector([sellerState], (state) => {
  return state.get('sellerConfig');
});

export const selectSellerConfigBySellerId = createSelector(
  [
    sellerConfig,
    (state: CommonAppState, { sellerId }: { sellerId: string }) => sellerId,
  ],
  (state, sellerId): SellerConfiguration => {
    return state.getIn([sellerId, 'value']) || undefined;
  },
);

export const sellerExports = createSelector([sellerState], (state) =>
  state.get('sellerExports'),
);

export const getSellersConfigWasLoaded = (state: CommonAppState): boolean =>
  requestSelectors.wasActionLoadedSelector(
    state,
    actionTypes.GET_SELLERS_CONFIGURATION,
  );

export const sellerConfigIsLoading = (
  state: CommonAppState,
  { timestamp }: SellerConfigArgs,
): boolean =>
  requestSelectors.isActionLoadingSelector(
    state,
    actionTypes.SET_SELLER_CONFIGURATION,
    timestamp,
  );
export const sellerLanguagesConfigIsLoading = (
  state: CommonAppState,
  { timestamp }: SellerConfigArgs,
): boolean =>
  requestSelectors.isActionLoadingSelector(
    state,
    actionTypes.SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES,
    timestamp,
  );

export const sellerLanguagesConfigWasLoaded = (
  state: CommonAppState,
  { timestamp }: SellerConfigArgs,
): boolean => {
  return requestSelectors.wasActionLoadedSelector(
    state,
    actionTypes.SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES,
    timestamp,
  );
};

export const sellerLanguagesConfigHasError = (
  state: CommonAppState,
  { timestamp }: SellerConfigArgs,
): ErrorFromBack[] => {
  return requestSelectors.getActionErrorsSelector(
    state,
    actionTypes.SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES,
    timestamp,
  );
};

export const sellerConfigWasLoaded = (
  state: CommonAppState,
  { timestamp }: SellerConfigArgs,
): boolean => {
  return requestSelectors.wasActionLoadedSelector(
    state,
    actionTypes.SET_SELLER_CONFIGURATION,
    timestamp,
  );
};

export const sellerConfigHasError = (
  state: CommonAppState,
  { timestamp }: SellerConfigArgs,
): boolean => {
  return requestSelectors.someActionsHasErrorSelector(state, [
    {
      action: actionTypes.SET_SELLER_CONFIGURATION,
      indexer: timestamp,
    },
  ]);
};
export const selectGetSellerConfigIsLoaded = (
  state: CommonAppState,
  { sellerId }: { sellerId: string },
): boolean => {
  return requestSelectors.wasActionLoadedSelector(
    state,
    actionTypes.GET_SELLER_CONFIGURATION,
    sellerId,
  );
};

export const sellerListStateSelector = createSelector([sellerState], (state) =>
  state.get('sellerList'),
);

export const sellerListValueSelector = createSelector(
  [sellerListStateSelector],
  (state) => state.get('value'),
);

export const sellerIsLoadingSelector = createSelector(
  [sellerListStateSelector, (state: CommonAppState, id: string) => id],
  (sellerList: any, id) => sellerList.get('idIsLoading').includes(id),
);
